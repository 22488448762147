import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'

import { ProfileBodyAboutFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { ProfileTemplateEnhancerProps } from '../templates/profile'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Text } from '../components/Text'

export type ProfileBodyAboutProps = ReturnType<typeof mapDataToProps> &
  ProfileTemplateEnhancerProps

const ProfileBodyAbout = ({
  textHTML,
  nextSharesBg,
}: ProfileBodyAboutProps) => (
  <BoundedBox
    component="section"
    variant="wide"
    nextSharesBg={nextSharesBg}
    styles={{
      backgroundColor: 'white',
      color: 'brown20',
      maxWidth: 'xlarge',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  >
    <Text
      component="h3"
      variant="sans-caps-semibold-13-18"
      styles={{
        color: 'fuschia30',
        textAlign: 'center',
        marginBottom: [4, 6, 8],
      }}
    >
      About the Entrepreneur
    </Text>

    {textHTML && (
      <HTMLContent
        html={textHTML}
        componentOverrides={{
          p: (Comp) => (props) => <Comp variant="sans-18-28" {...props} />,
        }}
        styles={{
          textAlign: 'center',
          width: [null, '10/12', '9/12'],
          marginLeft: [null, 'auto'],
          marginRight: [null, 'auto'],
        }}
      />
    )}
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<ProfileBodyAboutFragment, typeof mapDataToContext>) => ({
  textHTML: getRichText(data?.primary?.text),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const fragment = graphql`
  fragment ProfileBodyAbout on PrismicProfileBodyAbout {
    primary {
      text {
        text
        html
      }
    }
  }
`

export default ProfileBodyAbout
