import * as React from 'react'
import { graphql } from 'gatsby'
import { getRichText } from '@walltowall/helpers'
import { Box } from '@walltowall/calico'

import { ProfileBodyQAndAFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { ProfileTemplateEnhancerProps } from '../templates/profile'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { Text } from '../components/Text'

export type ProfileQAndAProps = ReturnType<typeof mapDataToProps> &
  ProfileTemplateEnhancerProps

const ProfileBodyQAndA = ({
  heading,
  textHTML,
  nextSharesBg,
}: ProfileQAndAProps) => (
  <BoundedBox
    component="section"
    nextSharesBg={nextSharesBg}
    styles={{
      backgroundColor: 'white',
      color: 'brown20',
      maxWidth: 'xlarge',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: [19, 30, 46],
    }}
  >
    <Box
      styles={{
        display: 'flex',
        flexDirection: ['column', 'row'],
        alignItems: [null, 'baseline'],
      }}
    >
      <Text
        component="h3"
        variant="sans-30-56"
        styles={{
          color: 'beige40',
          flexGrow: 1,
          marginBottom: [8, 0],
          marginRight: [null, 10, 12],
        }}
      >
        {heading}
      </Text>
      <Box
        styles={{
          width: [null, '8/12'],
          flexShrink: 0,
        }}
      >
        {textHTML && (
          <HTMLContent
            html={textHTML}
            componentOverrides={{
              h2: (Comp) => (props) => (
                <Comp
                  variant="sans-medium-18-28"
                  {...props}
                  styles={{ color: 'fuschia30' }}
                />
              ),
            }}
            styles={{
              maxWidth: '50rem',
            }}
          />
        )}
      </Box>
    </Box>
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<ProfileBodyQAndAFragment, typeof mapDataToContext>) => ({
  heading: data?.primary?.heading?.text,
  textHTML: getRichText(data?.primary?.text),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const fragment = graphql`
  fragment ProfileBodyQAndA on PrismicProfileBodyQAndA {
    primary {
      heading {
        text
      }
      text {
        text
        html
      }
    }
  }
`

export default ProfileBodyQAndA
