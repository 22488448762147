import * as React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { Box, useBoxStyles } from '@walltowall/calico'
import { AspectRatio } from '@walltowall/siamese'

import { ProfileBodyIntroductionFragment } from '../graphqlTypes'
import { MapDataToPropsArgs } from '../types'
import { ProfileTemplateEnhancerProps } from '../templates/profile'
import { useOrderedProfiles } from '../hooks/useOrderedProfiles'

import { BoundedBox } from '../components/BoundedBox'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'
import { Icon } from '../components/Icon'
import { Inline } from '../components/Inline'
import { Link } from '../components/Link'
import { Text } from '../components/Text'

import * as styleRefs from './ProfileBodyIntroduction.treat'
import VisuallyHidden from '@reach/visually-hidden'

export type ProfileBodyIntroductionProps = ReturnType<typeof mapDataToProps> &
  ProfileTemplateEnhancerProps

const ProfileBodyIntroduction = ({
  name,
  title,
  quote,
  photoFluid,
  photoFluidMobile,
  photoAlt,
  photoAltMobile,
  profileUid,
}: ProfileBodyIntroductionProps) => {
  const fullHeight = useBoxStyles({ height: 'full' })

  const orderedProfiles = useOrderedProfiles()
  const index = orderedProfiles.findIndex(
    (profile) => profile.uid === profileUid,
  )
  const nextProfile =
    orderedProfiles[index + 1 > orderedProfiles.length - 1 ? 0 : index + 1]
  const previousProfile =
    orderedProfiles[index - 1 < 0 ? orderedProfiles.length - 1 : index - 1]

  return (
    <Box
      component="section"
      className={styleRefs.diamondBackground}
      styles={{
        backgroundColor: 'gold70',
        color: 'brown20',
        maxWidth: 'xlarge',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: ['column', 'row'],
      }}
    >
      <BoundedBox
        styles={{
          width: [null, '6/12'],
          flexShrink: 0,
          paddingRight: [6, null, 9, 14],
          paddingBottom: [6, 10, 19, 22],
        }}
      >
        <Box
          styles={{ display: 'flex', flexDirection: 'column', height: 'full' }}
        >
          {name && (
            <Text
              component="h2"
              variant="sans-30-56"
              styles={{ color: 'white', marginBottom: [3, 4, 6] }}
            >
              {name}
            </Text>
          )}
          {title && (
            <Text
              variant="sans-medium-18-28"
              styles={{
                color: 'white',
                marginBottom: [7, 9, 11],
              }}
            >
              {title}
            </Text>
          )}
          {quote && (
            <Box component="blockquote" styles={{ position: 'relative' }}>
              <Text
                variant="serif-24-40"
                className={styleRefs.translateQuotationMark}
                styles={{ position: 'absolute', left: 0, fontStyle: 'italic' }}
              >
                &ldquo;
              </Text>
              <Text variant="serif-24-40" styles={{ fontStyle: 'italic' }}>
                {quote}&rdquo;
              </Text>
            </Box>
          )}
          <Box
            styles={{
              display: 'flex',
              justifyContent: 'end',
              flexGrow: 1,
              flexDirection: 'column',
              width: [null, '4/12'],
              marginTop: [12, 15, 20],
            }}
          >
            <Text
              variant="sans-caps-semibold-13-18"
              styles={{ color: 'fuschia30', marginBottom: [3, 5, 6] }}
            >
              See More Entrepreneurs
            </Text>
            <Inline space={[3, 4, 6]}>
              {previousProfile.url && (
                <Link href={previousProfile.url}>
                  <VisuallyHidden>Previous entrepreneur</VisuallyHidden>
                  <Icon
                    name="arrowLeft"
                    styles={{
                      width: ['1.375rem', '2.5rem'],
                      color: 'fuschia30',
                      transitionProperty: 'color',
                      transitionDuration: 'normal',
                    }}
                    hoverStyles={{ color: 'maroon10' }}
                    focusStyles={{ color: 'maroon10' }}
                  />
                </Link>
              )}
              {nextProfile.url && (
                <Link href={nextProfile.url}>
                  <VisuallyHidden>Next entrepreneur</VisuallyHidden>
                  <Icon
                    name="arrowRight"
                    styles={{
                      width: ['1.375rem', '2.5rem'],
                      color: 'fuschia30',
                      transitionProperty: 'color',
                      transitionDuration: 'normal',
                    }}
                    hoverStyles={{ color: 'maroon10' }}
                    focusStyles={{ color: 'maroon10' }}
                  />
                </Link>
              )}
            </Inline>
          </Box>
        </Box>
      </BoundedBox>
      <Box
        styles={{
          width: [null, '6/12'],
          flexShrink: 0,
          backgroundColor: 'brown20',
        }}
      >
        <GatsbyImageContainer styles={{ display: [null, 'none'] }}>
          <AspectRatio x={16} y={9}>
            {photoFluidMobile && (
              <GatsbyImage fluid={photoFluidMobile} alt={photoAltMobile} />
            )}
            {!photoFluidMobile && photoFluid && (
              <GatsbyImage fluid={photoFluid} alt={photoAlt} />
            )}
          </AspectRatio>
        </GatsbyImageContainer>
        <GatsbyImageContainer
          styles={{ display: ['none', 'block'], height: 'full' }}
        >
          <AspectRatio x={7} y={8} className={fullHeight}>
            {photoFluid && <GatsbyImage fluid={photoFluid} alt={photoAlt} />}
          </AspectRatio>
        </GatsbyImageContainer>
      </Box>
    </Box>
  )
}

export const mapDataToProps = ({
  data,
  meta,
}: MapDataToPropsArgs<
  ProfileBodyIntroductionFragment,
  typeof mapDataToContext
>) => ({
  name: data.primary?.profile_name?.text,
  title: data.primary?.profile_title?.text,
  quote: data.primary?.quote?.text,
  photoFluid: data.primary?.photo?.fluid,
  photoFluidMobile: data.primary?.photo?.thumbnails?.mobile?.fluid,
  photoAlt: data.primary?.photo?.alt,
  photoAltMobile: data.primary?.photo?.thumbnails?.mobile?.alt,
  profileUid: meta?.rootData?.prismicProfile?.uid as string,
})

export const mapDataToContext = () => ({
  bg: Symbol(),
})

export const fragment = graphql`
  fragment ProfileBodyIntroduction on PrismicProfileBodyIntroduction {
    primary {
      profile_name {
        text
      }
      profile_title {
        text
      }
      quote {
        text
      }
      photo {
        alt
        fluid(maxWidth: 500) {
          ...GatsbyPrismicImageFluid
        }
        thumbnails {
          mobile {
            alt
            fluid(maxWidth: 400) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

export default ProfileBodyIntroduction
