import { graphql } from 'gatsby'

import { ProfileTemplateEnhancerProps } from '../templates/profile'

import * as PageBodyQuote from './PageBodyQuote'

export type ProfileQuoteProps = ReturnType<typeof mapDataToProps> &
  ProfileTemplateEnhancerProps

const ProfileBodyQuote = PageBodyQuote.default

export const mapDataToProps = PageBodyQuote.mapDataToProps

export const mapDataToContext = PageBodyQuote.mapDataToContext

export const fragment = graphql`
  fragment ProfileBodyQuote on PrismicProfileBodyQuote {
    primary {
      quote {
        text
      }
      quotee {
        text
      }
      quotee_business {
        text
      }
      image {
        alt
        fluid(maxWidth: 500) {
          ...GatsbyPrismicImageFluid
        }
        thumbnails {
          mobile {
            alt
            fluid(maxWidth: 400) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`

export default ProfileBodyQuote
