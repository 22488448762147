/**
 * Root Prismic slices file where Profile Body slices are registered for use in the
 * profile template file.
 *
 * @see /docs/guide-create-a-slice.md for more details.
 */

import { graphql } from 'gatsby'
import { map } from 'fp-ts/es6/Record'

// 1. Import your slice
import * as ProfileBodyIntroduction from './ProfileBodyIntroduction'
import * as ProfileBodyAbout from './ProfileBodyAbout'
import * as ProfileBodyVideos from './ProfileBodyVideos'
import * as ProfileBodyQAndA from './ProfileBodyQAndA'
import * as ProfileBodyQuote from './ProfileBodyQuote'

// 2. Add your slice
const slices = {
  ProfileBodyIntroduction,
  ProfileBodyAbout,
  ProfileBodyVideos,
  ProfileBodyQAndA,
  ProfileBodyQuote,
} as const

// 3. Add your slice fragment
export const fragment = graphql`
  fragment SlicesProfileBody on PrismicProfileBodySlicesType {
    ...ProfileBodyIntroduction
    ...ProfileBodyAbout
    ...ProfileBodyVideos
    ...ProfileBodyQAndA
    ...ProfileBodyQuote
  }
`

type ValueOf<T> = T[keyof T]

export const slicesMap = map((module: ValueOf<typeof slices>) => {
  const component: typeof module.default & {
    mapDataToProps?: any
    mapDataToContext?: any
  } = module.default
  if ('mapDataToProps' in module)
    component.mapDataToProps = module.mapDataToProps
  if ('mapDataToContext' in module)
    component.mapDataToContext = module.mapDataToContext
  return component
})(slices)
